import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import FeedbackEmail from './components/FeedbackEmail/FeedbackEmail';
import FeedbackProductApproved from './components/FeedbackProductApproved/FeedbackProductApproved';
import FeedbackProductDenied from './components/FeedbackProductDenied/FeedbackProductDenied';
import DecisionStepProps from './DecisionStepProps';
import FeedbackProductApprovedExperiment from './components/FeedbackProductApprovedExperiment/FeedbackProductApprovedExperiment';
import FeedbackCuentaReward from './components/FeedbackCuentaReward/FeedbackCuentaReward';

const DecisionStep = ({
  prospectEmail,
  prospectNames,
  realtimeResult,
  onFormStepChange,
  formExperiments,
}) => {
  useEffect(() => {
    onFormStepChange(false);
  }, []);

  const isCulturalHackExperimentAOn = formExperiments?.isCulturalHackExperimentAOn;
  const isCulturalHackExperimentBOn = formExperiments?.isCulturalHackExperimentBOn;
  const { analysisResult } = realtimeResult;
  const defaultApprovedProps = DecisionStepProps.approved.multiProduct;
  const productsApproved = analysisResult?.template?.id;
  const multiProductsApprovedProps = productsApproved === 'credit_card_and_nuconta' ? DecisionStepProps.approved.multiProductClarity : DecisionStepProps.approved.singleProductClarity;
  switch (productsApproved) {
    case 'credit_card':
      return (
        <FeedbackProductApproved
          prospectEmail={prospectEmail}
          prospectNames={prospectNames}
          defaultProps={defaultApprovedProps}
        />
      );
    case 'nuconta':
    case 'credit_card_and_nuconta':
      return (
        (isCulturalHackExperimentAOn || isCulturalHackExperimentBOn)
          ? (
            <FeedbackCuentaReward
              prospectEmail={prospectEmail}
              prospectNames={prospectNames}
              multiProduct
              defaultProps={DecisionStepProps.approved.culturalHack}
            />
          )
          : (
            <FeedbackProductApprovedExperiment
              prospectEmail={prospectEmail}
              prospectNames={prospectNames}
              multiProduct
              defaultProps={multiProductsApprovedProps}
            />
          )
      );
    case 'no_products':
      return (
        <FeedbackProductDenied
          prospectEmail={prospectEmail}
        />
      );
    default:
      return (
        <FeedbackEmail
          prospectEmail={prospectEmail}
          prospectNames={prospectNames}
        />
      );
  }
};

DecisionStep.defaultProps = {
  formExperiments: {},
  onFormStepChange: () => {},
};

DecisionStep.propTypes = {
  formExperiments: PropTypes.shape({
    isCulturalHackExperimentAOn: PropTypes.bool,
    isCulturalHackExperimentBOn: PropTypes.bool,
  }),
  onFormStepChange: PropTypes.func,
  prospectEmail: PropTypes.string.isRequired,
  prospectNames: PropTypes.string.isRequired,
  realtimeResult: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      analysisResult: PropTypes.shape({
        template: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      prospect: PropTypes.shape({
        marketingId: PropTypes.string.isRequired,
        prospectType: PropTypes.string.isRequired,
      }),
    }),
  ]).isRequired,
};

export default DecisionStep;
