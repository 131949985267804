import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import PropTypes from 'prop-types';

import { SelectedIcon } from './Icons';
import DefaultProps from './variant/ButtonGridProps';
import {
  Container, Title, ButtonGrid, StyledButton, PlaceholderIcon,
} from './styles/ButtonGridStyles';

const ButtonGridComponent = ({ onSelectGoal }) => {
  const {
    title,
    goals,
  } = DefaultProps;
  const { formatMessage } = useIntl();

  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (id, description) => {
    setSelectedButton(id);
    const formattedMessage = formatMessage({ id: description });
    onSelectGoal(formattedMessage);
  };

  const ButtonComponent = ({
    id, description, icon, isSelected, onClick,
  }) => {
    const IconComponent = icon;
    return (
      <StyledButton isSelected={isSelected} onClick={() => onClick(id)}>
        <>
          <IconComponent />
          <Typography variant="button" color="primary" colorVariant="default" intlKey={description} />
        </>
        {isSelected ? <SelectedIcon /> : <PlaceholderIcon />}
      </StyledButton>
    );
  };

  ButtonComponent.propTypes = {
    description: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  return (
    <Container>
      <Title color="primary" variant="heading4" intlKey={title} />
      <ButtonGrid>
        {goals.map(button => (
          <ButtonComponent
            key={button.id}
            id={button.id}
            description={button.description}
            icon={button.icon}
            isSelected={selectedButton === button.id}
            onClick={() => handleButtonClick(button.id, button.description)}
          />
        ))}
      </ButtonGrid>
    </Container>
  );
};

ButtonGridComponent.defaultProps = {
  onSelectGoal: () => {},
};

ButtonGridComponent.propTypes = {
  onSelectGoal: PropTypes.func,
};

export default ButtonGridComponent;
