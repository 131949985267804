import React, { useState, useContext } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';
import Box from '@nubank/nuds-web/components/Box/Box';

import {
  hasTwoOrMoreCharsOnFirstWord,
  optionalHasTwoOrMoreCharsOnFirstWord,
} from '@nubank/www-latam-commons/utils/form/validationUtils';
import { documentTypes } from 'utils/documentTypes';
import { SiteContext } from 'components/SiteContext/SiteContext';
import useActiveVariation from '@nubank/www-latam-commons/utils/figpii/useActiveVariation';

import { validateDocumentID, validatePhone, hasLessThanTwentyChars } from '../../../utils/form/validationUtils';
import { CULTURAL_HACK, CULTURAL_HACK_GOALS, REDEBAN } from '../../../utils/experiments';

import RegistrationRealtimeLoader from './patterns/RegistrationRealtimeLoader/RegistrationRealtimeLoader';
import FormHeader from './patterns/FormHeader/FormHeader';
import PersonalInfoStep from './patterns/PersonalInfoStep/PersonalInfoStep';
import DecisionStep from './patterns/DecisionStep/DecisionStep';
import { StepWrapper, LoadingStepWrapper, ResultStepWrapper } from './styles/StepWrapper';
import { StyledForm } from './styles/StyledForm';
import personalInfoStepProps, { culturalHackTreatmentAProps, culturalHackTreatmentBProps } from './patterns/PersonalInfoStep/PersonalInfoStepProps';

export const extraValidations = {
  firstSurname: value => hasTwoOrMoreCharsOnFirstWord(value),
  nineteenMaxLength: hasLessThanTwentyChars,
  names: value => hasTwoOrMoreCharsOnFirstWord(value),
  secondSurname: optionalHasTwoOrMoreCharsOnFirstWord,
  phoneNumber: validatePhone,
  idNumber: validateDocumentID,
};

const ccDocumentType = documentTypes.find(({ value }) => value === 'CC').value;

const formInitialValues = {
  confirmEmail: '',
  names: '',
  firstSurname: '',
  secondSurname: '',
  idNumber: '',
  phoneNumber: '',
  policyAccepted: false,
  whatsappAccepted: false,
  documentType: ccDocumentType,
};

const ProspectRegistrationForm = () => {
  const [realtimeResult, setRealtimeResult] = useState(false);
  const [shouldShowFixedHeader, setShouldShowFixedHeader] = useState(false);
  const { usePurpleAsBackgroundInRegistrationForm } = useContext(SiteContext);

  const formExperiments = {};

  const redebanActiveVariant = useActiveVariation(REDEBAN.id);
  const isRedebanExperimentOn = redebanActiveVariant === REDEBAN.treatment;

  const cualturalHackActiveVariant = useActiveVariation(CULTURAL_HACK.id);
  const isCulturalHackExperimentAOn = isRedebanExperimentOn ? false
    : (cualturalHackActiveVariant === CULTURAL_HACK.treatmentA);
  const isCulturalHackExperimentBOn = isRedebanExperimentOn ? false
    : (cualturalHackActiveVariant === CULTURAL_HACK.treatmentB);

  const goalsActiveVariant = useActiveVariation(CULTURAL_HACK_GOALS.id);
  const isCulturalHackGoalsExperimentOn = goalsActiveVariant === CULTURAL_HACK_GOALS.treatment;

  formExperiments.isCulturalHackExperimentAOn = isCulturalHackExperimentAOn;
  formExperiments.isCulturalHackExperimentBOn = isCulturalHackExperimentBOn;
  formExperiments.isCulturalHackGoalsExperimentOn = isCulturalHackGoalsExperimentOn;

  const personalInfoStepPropsMap = {
    [CULTURAL_HACK.treatmentA]: culturalHackTreatmentAProps,
    [CULTURAL_HACK.treatmentB]: culturalHackTreatmentBProps,
    [CULTURAL_HACK.control]: personalInfoStepProps,
  };

  const personalInfoStepVariantProps = (isCulturalHackExperimentAOn || isCulturalHackExperimentBOn)
    ? personalInfoStepPropsMap[cualturalHackActiveVariant]
    : personalInfoStepPropsMap[CULTURAL_HACK.control];

  return (
    <StyledForm
      renderHeader={() => (
        <FormHeader
          displayFixedHeader={shouldShowFixedHeader}
          formExperiments={formExperiments}
        />
      )}
      extraValidations={extraValidations}
    >
      <StepWrapper>
        <PersonalInfoStep
          setRealtimeResult={setRealtimeResult}
          finalInitialValues={{ ...formInitialValues }}
          formExperiments={formExperiments}
          defaultProps={personalInfoStepVariantProps}
          onFormStepChange={setShouldShowFixedHeader}
        />
      </StepWrapper>
      <LoadingStepWrapper>
        <Form.Step isForm={false}>
          <Box>
            <RegistrationRealtimeLoader
              onFormStepChange={setShouldShowFixedHeader}
            />
          </Box>
        </Form.Step>
      </LoadingStepWrapper>
      <ResultStepWrapper approved={usePurpleAsBackgroundInRegistrationForm}>
        <Form.Step isForm={false}>
          {({ formsValues }) => (
            <DecisionStep
              prospectEmail={formsValues.email}
              prospectNames={formsValues.names || ''}
              realtimeResult={realtimeResult}
              formExperiments={formExperiments}
              onFormStepChange={setShouldShowFixedHeader}
            />
          )}
        </Form.Step>
      </ResultStepWrapper>
    </StyledForm>
  );
};

export default ProspectRegistrationForm;
