import React from 'react';

export const ButtonIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" fill="none" />
  </svg>
);

export const LightHouseIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#CEBAF4"
      d="M14.048 9.57v12.338H.531A.535.535 0 0 1 0 21.371V9.57h14.048Z"
    />
    <path
      fill="#DCDAEA"
      d="M24 9.57v11.8a.535.535 0 0 1-.531.538h-9.42V9.57l4.98-7.695L24 9.57Z"
    />
    <mask
      id="b"
      width={10}
      height={21}
      x={14}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#a)"
        d="M24 9.57v11.8a.535.535 0 0 1-.531.538h-9.42V9.57l4.98-7.695L24 9.57Z"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fill="#CEBAF4"
        d="M24 9.57v11.8a.535.535 0 0 1-.531.538h-9.42V9.57l4.98-7.695L24 9.57Z"
      />
    </g>
    <path
      fill="#CEBAF4"
      d="M17.08 13.898h3.9c.2 0 .363.164.363.366v7.644h-4.626v-7.644c0-.202.164-.366.364-.366h-.002Z"
    />
    <mask
      id="d"
      width={6}
      height={9}
      x={16}
      y={13}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#c)"
        d="M17.08 13.898h3.9c.2 0 .363.164.363.366v7.644h-4.626v-7.644c0-.202.164-.366.364-.366h-.002Z"
      />
    </mask>
    <g mask="url(#d)">
      <path
        fill="#820AD1"
        d="M17.08 13.898h3.9c.2 0 .363.164.363.366v7.644h-4.626v-7.644c0-.202.164-.366.364-.366h-.002Z"
      />
    </g>
    <path
      fill="#CEBAF4"
      d="m19.028 1.875-4.98 7.695H0l4.84-7.237c.197-.288.523-.458.87-.458h13.318Z"
    />
    <mask
      id="f"
      width={20}
      height={9}
      x={0}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#e)"
        d="m19.028 1.875-4.98 7.695H0l4.84-7.237c.197-.288.523-.458.87-.458h13.318Z"
      />
    </mask>
    <g mask="url(#f)">
      <path
        fill="#820AD1"
        d="m19.028 1.875-4.98 7.695H0l4.84-7.237c.197-.288.523-.458.87-.458h13.318Z"
      />
    </g>
    <path
      fill="#CEBAF4"
      d="M10.034 12.51H3.28c-.171 0-.31.14-.31.313v4.131c0 .173.139.313.31.313h6.754c.172 0 .311-.14.311-.313v-4.131a.312.312 0 0 0-.31-.313Z"
    />
    <mask
      id="h"
      width={9}
      height={6}
      x={2}
      y={12}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#g)"
        d="M10.034 12.51H3.28c-.171 0-.31.14-.31.313v4.131c0 .173.139.313.31.313h6.754c.172 0 .311-.14.311-.313v-4.131a.312.312 0 0 0-.31-.313Z"
      />
    </mask>
    <g mask="url(#h)">
      <path
        fill="#820AD1"
        d="M10.034 12.51H3.28c-.171 0-.31.14-.31.313v4.131c0 .173.139.313.31.313h6.754c.172 0 .311-.14.311-.313v-4.131a.312.312 0 0 0-.31-.313Z"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={14.048}
        x2={24}
        y1={11.892}
        y2={11.892}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={16.717}
        x2={21.437}
        y1={17.904}
        y2={17.904}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.1} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={2.458}
        x2={16.619}
        y1={5.723}
        y2={5.723}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.1} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={2.95}
        x2={10.325}
        y1={14.889}
        y2={14.889}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.1} />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
);

export const LightFlagIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#AA68FF"
        d="M19.903 7.587s-1.808 3.33-7.28 2.71a.61.61 0 0 1-.28-.1.647.647 0 0 1-.288-.397l-.194-.798c.315.017.645.051.99.102l-1.537-6.309c5.062.355 6.75-2.758 6.75-2.758l1.84 7.55Z"
      />
      <path
        fill="#AA68FF"
        d="M12.851 9.104a10.97 10.97 0 0 0-.99-.102c-2.393-.136-3.834.661-4.683 1.522-.91.922-1.144 1.915-1.144 1.915L4.257 5.146a.943.943 0 0 1 .034-.554 4.727 4.727 0 0 1 1.048-1.62c.905-.915 2.478-1.762 5.16-1.485a.73.73 0 0 1 .632.551l.183.757 1.537 6.309Z"
      />
      <path
        fill="#820AD1"
        d="m5.337 2.972 4.878 20.024a.783.783 0 0 1-1.521.37L4.257 5.147a.95.95 0 0 1 .034-.556 4.76 4.76 0 0 1 1.046-1.62v.002Z"
      />
      <mask
        id="c"
        width={9}
        height={11}
        x={11}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="url(#b)"
          d="M19.903 7.587s-1.808 3.33-7.28 2.71a.61.61 0 0 1-.28-.1.647.647 0 0 1-.288-.397l-.194-.798c.315.017.645.051.99.102l-1.537-6.309c5.062.355 6.75-2.758 6.75-2.758l1.84 7.55Z"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill="#CEBAF4"
          d="M19.903 7.587s-1.808 3.33-7.28 2.71a.61.61 0 0 1-.28-.1.647.647 0 0 1-.288-.397l-.194-.798c.315.017.645.051.99.102l-1.537-6.309c5.062.355 6.75-2.758 6.75-2.758l1.84 7.55Z"
        />
      </g>
      <path
        fill="#820AD1"
        d="M12.852 9.104s-.034.576-.509 1.093a.647.647 0 0 1-.288-.397l-.193-.798c.314.018.644.051.99.102Z"
        opacity={0.8}
      />
      <mask
        id="e"
        width={9}
        height={12}
        x={4}
        y={1}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="url(#d)"
          d="M12.851 9.104a10.97 10.97 0 0 0-.99-.102c-2.393-.136-3.834.661-4.683 1.522-.91.922-1.144 1.915-1.144 1.915L4.257 5.146a.943.943 0 0 1 .034-.554 4.727 4.727 0 0 1 1.048-1.62c.905-.915 2.478-1.762 5.16-1.485a.73.73 0 0 1 .632.551l.183.757 1.537 6.309Z"
        />
      </mask>
      <g mask="url(#e)">
        <path
          fill="#CEBAF4"
          d="M12.851 9.104a10.97 10.97 0 0 0-.99-.102c-2.393-.136-3.834.661-4.683 1.522-.91.922-1.144 1.915-1.144 1.915L4.257 5.146a.943.943 0 0 1 .034-.554 4.727 4.727 0 0 1 1.048-1.62c.905-.915 2.478-1.762 5.16-1.485a.73.73 0 0 1 .632.551l.183.757 1.537 6.309Z"
        />
      </g>
      <path
        fill="#AA68FF"
        d="M5.337 2.972A4.727 4.727 0 0 0 4.29 4.59a.924.924 0 0 0-.058.275v.02a.938.938 0 0 0 .025.26l.829 3.407.948 3.887s.233-.993 1.143-1.914L5.337 2.972Z"
        opacity={0.8}
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={11.315}
        x2={19.903}
        y1={5.206}
        y2={5.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={4.229}
        x2={12.851}
        y1={6.937}
        y2={6.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M4.125 0h15.882v24H4.125z" />
      </clipPath>
    </defs>
  </svg>
);

export const LightHumanIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <mask
      id="b"
      width={10}
      height={7}
      x={7}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        stroke="url(#a)"
        strokeMiterlimit={10}
        strokeWidth={10}
        d="M12 6.177V.89"
      />
    </mask>
    <g mask="url(#b)">
      <path
        stroke="#CEBAF4"
        strokeMiterlimit={10}
        strokeWidth={10}
        d="M12 6.177V.89"
      />
    </g>
    <path
      fill="#DCDAEA"
      d="M23.679 24h-5.547l.305-1.988a2.497 2.497 0 0 1 4.938 0L23.681 24h-.002Z"
    />
    <path
      fill="#D2A9C2"
      d="M20.905 19.894a1.26 1.26 0 1 0 0-2.519 1.26 1.26 0 0 0 0 2.519Z"
    />
    <mask
      id="d"
      width={4}
      height={3}
      x={19}
      y={17}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#c)"
        d="M20.905 19.894a1.26 1.26 0 1 0 0-2.519 1.26 1.26 0 0 0 0 2.519Z"
      />
    </mask>
    <g mask="url(#d)">
      <path
        fill="#62029B"
        d="M20.905 19.894a1.26 1.26 0 1 0 0-2.519 1.26 1.26 0 0 0 0 2.519Z"
      />
    </g>
    <path
      fill="#CEBAF4"
      d="M6.23 24H.32l.503-4.703a2.466 2.466 0 0 1 4.903 0l.504 4.702Z"
    />
    <path
      fill="#AD8001"
      d="M3.274 17.093a1.347 1.347 0 1 0 0-2.694 1.347 1.347 0 0 0 0 2.694Z"
    />
    <mask
      id="f"
      width={4}
      height={4}
      x={1}
      y={14}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="url(#e)"
        d="M3.274 17.093a1.347 1.347 0 1 0 0-2.694 1.347 1.347 0 0 0 0 2.694Z"
      />
    </mask>
    <g mask="url(#f)">
      <path
        fill="#62029B"
        d="M3.274 17.093a1.347 1.347 0 1 0 0-2.694 1.347 1.347 0 0 0 0 2.694Z"
      />
    </g>
    <path fill="#820AD1" d="M9.76 6.143h4.48L12 8.316 9.76 6.143Z" />
    <path
      fill="#AA68FF"
      d="M7.325 20.627c.278-2.11 2.088-3.686 4.23-3.686h.89c2.142 0 3.952 1.577 4.23 3.686L17.12 24H6.88l.445-3.373Z"
    />
    <ellipse cx={11.929} cy={14.611} fill="#62029B" rx={2.489} ry={2.471} />
    <mask
      id="h"
      width={6}
      height={6}
      x={9}
      y={12}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <ellipse cx={11.929} cy={14.611} fill="url(#g)" rx={2.489} ry={2.471} />
    </mask>
    <g mask="url(#h)">
      <ellipse cx={11.929} cy={14.611} fill="#AD8001" rx={2.489} ry={2.471} />
    </g>
    <ellipse cx={11.893} cy={11.082} fill="#000" rx={1.493} ry={1.482} />
    <mask
      id="j"
      width={4}
      height={4}
      x={10}
      y={9}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <ellipse cx={11.893} cy={11.082} fill="url(#i)" rx={1.493} ry={1.482} />
    </mask>
    <g mask="url(#j)">
      <ellipse cx={11.893} cy={11.082} fill="#62029B" rx={1.493} ry={1.482} />
    </g>
    <mask
      id="k"
      width={6}
      height={6}
      x={9}
      y={12}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <ellipse cx={11.929} cy={14.611} fill="#D9D9D9" rx={2.489} ry={2.471} />
    </mask>
    <g fill="#62029B" mask="url(#k)">
      <ellipse cx={13.066} cy={12.07} rx={2.489} ry={2.471} />
      <ellipse cx={8.8} cy={12.07} rx={2.489} ry={2.471} />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={12}
        x2={12}
        y1={6.177}
        y2={1.093}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={20.905}
        x2={20.905}
        y1={20.57}
        y2={19.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={3.274}
        x2={3.274}
        y1={17.612}
        y2={16.218}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={12}
        x2={12}
        y1={12.141}
        y2={17.082}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={0.488} />
        <stop offset={1} stopOpacity={0.75} />
      </linearGradient>
      <linearGradient
        id="i"
        x1={12}
        x2={12}
        y1={11.153}
        y2={15.105}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export const LightCloverIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g opacity={0.9}>
      <path
        fill="#AA68FF"
        fillRule="evenodd"
        d="M7.543.75H5.657a2.571 2.571 0 0 0-2.571 2.571c0 .284-.23.515-.515.515A2.571 2.571 0 0 0 0 6.407v1.886c0 2.84 1.617 4.457 4.457 4.457H12V5.207C12 2.367 10.383.75 7.543.75ZM16.457 24.75h1.886a2.571 2.571 0 0 0 2.571-2.571c0-.284.23-.515.515-.515A2.571 2.571 0 0 0 24 19.093v-1.886c0-2.84-1.617-4.457-4.457-4.457H12v7.543c0 2.84 1.617 4.457 4.457 4.457Z"
        clipRule="evenodd"
      />
      <path
        fill="#CEBAF4"
        fillRule="evenodd"
        d="M0 17.207v1.886a2.571 2.571 0 0 0 2.571 2.571c.284 0 .515.23.515.515a2.571 2.571 0 0 0 2.571 2.571h1.886c2.84 0 4.457-1.617 4.457-4.457V12.75H4.457C1.617 12.75 0 14.367 0 17.207ZM24 8.293V6.407a2.571 2.571 0 0 0-2.571-2.571.514.514 0 0 1-.515-.515A2.571 2.571 0 0 0 18.343.75h-1.886C13.617.75 12 2.367 12 5.207v7.543h7.543c2.84 0 4.457-1.617 4.457-4.457Z"
        clipRule="evenodd"
      />
      <mask
        id="e"
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="url(#a)"
          fillRule="evenodd"
          d="M7.543.75H5.657a2.571 2.571 0 0 0-2.571 2.571c0 .284-.23.515-.515.515A2.571 2.571 0 0 0 0 6.407v1.886c0 2.84 1.617 4.457 4.457 4.457H12V5.207C12 2.367 10.383.75 7.543.75Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#b)"
          fillRule="evenodd"
          d="M16.457 24.75h1.886a2.571 2.571 0 0 0 2.571-2.571c0-.284.23-.515.515-.515A2.571 2.571 0 0 0 24 19.093v-1.886c0-2.84-1.617-4.457-4.457-4.457H12v7.543c0 2.84 1.617 4.457 4.457 4.457Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="M0 17.207v1.886a2.571 2.571 0 0 0 2.571 2.571c.284 0 .515.23.515.515a2.571 2.571 0 0 0 2.571 2.571h1.886c2.84 0 4.457-1.617 4.457-4.457V12.75H4.457C1.617 12.75 0 14.367 0 17.207Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#d)"
          fillRule="evenodd"
          d="M24 8.293V6.407a2.571 2.571 0 0 0-2.571-2.571.514.514 0 0 1-.515-.515A2.571 2.571 0 0 0 18.343.75h-1.886C13.617.75 12 2.367 12 5.207v7.543h7.543c2.84 0 4.457-1.617 4.457-4.457Z"
          clipRule="evenodd"
        />
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#e)">
        <path
          fill="#CEBAF4"
          d="M7.543.75H5.657a2.571 2.571 0 0 0-2.571 2.571c0 .284-.23.515-.515.515A2.571 2.571 0 0 0 0 6.407v1.886c0 2.84 1.617 4.457 4.457 4.457H12V5.207C12 2.367 10.383.75 7.543.75ZM16.457 24.75h1.886a2.571 2.571 0 0 0 2.571-2.571c0-.284.23-.515.515-.515A2.571 2.571 0 0 0 24 19.093v-1.886c0-2.84-1.617-4.457-4.457-4.457H12v7.543c0 2.84 1.617 4.457 4.457 4.457Z"
        />
        <path
          fill="#AA68FF"
          d="M0 17.207v1.886a2.571 2.571 0 0 0 2.571 2.571c.284 0 .515.23.515.515a2.571 2.571 0 0 0 2.571 2.571h1.886c2.84 0 4.457-1.617 4.457-4.457V12.75H4.457C1.617 12.75 0 14.367 0 17.207ZM24 8.293V6.407a2.571 2.571 0 0 0-2.571-2.571.514.514 0 0 1-.515-.515A2.571 2.571 0 0 0 18.343.75h-1.886C13.617.75 12 2.367 12 5.207v7.543h7.543c2.84 0 4.457-1.617 4.457-4.457Z"
        />
      </g>
      <mask
        id="j"
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="url(#f)"
          fillRule="evenodd"
          d="M16.457 24.75h1.886a2.571 2.571 0 0 0 2.571-2.571c0-.284.23-.515.515-.515A2.571 2.571 0 0 0 24 19.093v-1.886c0-2.84-1.617-4.457-4.457-4.457H12v7.543c0 2.84 1.617 4.457 4.457 4.457Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#g)"
          fillRule="evenodd"
          d="M24 8.293V6.407a2.571 2.571 0 0 0-2.571-2.571.514.514 0 0 1-.515-.515A2.571 2.571 0 0 0 18.343.75h-1.886C13.617.75 12 2.367 12 5.207v7.543h7.543c2.84 0 4.457-1.617 4.457-4.457Z"
          clipRule="evenodd"
          opacity={0.6}
        />
        <path
          fill="url(#h)"
          fillRule="evenodd"
          d="M0 17.207v1.886a2.571 2.571 0 0 0 2.571 2.571c.284 0 .515.23.515.515a2.571 2.571 0 0 0 2.571 2.571h1.886c2.84 0 4.457-1.617 4.457-4.457V12.75H4.457C1.617 12.75 0 14.367 0 17.207Z"
          clipRule="evenodd"
          opacity={0.6}
        />
        <path
          fill="url(#i)"
          fillRule="evenodd"
          d="M7.543.75H5.657a2.571 2.571 0 0 0-2.571 2.571c0 .284-.23.515-.515.515A2.571 2.571 0 0 0 0 6.407v1.886c0 2.84 1.617 4.457 4.457 4.457H12V5.207C12 2.367 10.383.75 7.543.75Z"
          clipRule="evenodd"
        />
      </mask>
      <g fill="#820AD1" fillRule="evenodd" clipRule="evenodd" mask="url(#j)">
        <path
          d="M16.457 24.75h1.886a2.571 2.571 0 0 0 2.571-2.571c0-.284.23-.515.515-.515A2.571 2.571 0 0 0 24 19.093v-1.886c0-2.84-1.617-4.457-4.457-4.457H12v7.543c0 2.84 1.617 4.457 4.457 4.457Z"
          opacity={0.9}
        />
        <path
          d="M0 17.207v1.886a2.571 2.571 0 0 0 2.571 2.571c.284 0 .515.23.515.515a2.571 2.571 0 0 0 2.571 2.571h1.886c2.84 0 4.457-1.617 4.457-4.457V12.75H4.457C1.617 12.75 0 14.367 0 17.207ZM24 8.293V6.407a2.571 2.571 0 0 0-2.571-2.571.514.514 0 0 1-.515-.515A2.571 2.571 0 0 0 18.343.75h-1.886C13.617.75 12 2.367 12 5.207v7.543h7.543c2.84 0 4.457-1.617 4.457-4.457Z"
          opacity={0.6}
        />
        <path
          d="M7.543.75H5.657a2.571 2.571 0 0 0-2.571 2.571c0 .284-.23.515-.515.515A2.571 2.571 0 0 0 0 6.407v1.886c0 2.84 1.617 4.457 4.457 4.457H12V5.207C12 2.367 10.383.75 7.543.75Z"
          opacity={0.9}
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id="f"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-135 8.64 3.89) scale(8.72771 9.24723)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.75} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="g"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(135 3.36 8.86) scale(8.72771 9.24723)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.75} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="h"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-45 21.39 -8.11) scale(8.72771 9.24723)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.75} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="i"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(45 -9.39 20.86) scale(8.72771 9.24723)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.75} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="a"
        x1={0}
        x2={12}
        y1={0.75}
        y2={13.264}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={24}
        x2={12}
        y1={24.75}
        y2={12.236}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={12}
        x2={0}
        y1={24.75}
        y2={12.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={12}
        x2={24}
        y1={0.75}
        y2={12.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export const CheckedIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.21643 10.2413C5.98863 10.4691 5.61928 10.4691 5.39147 10.2413L2.09174 6.9416L2.9167 6.11664L5.80395 9.00389L11.5787 3.22919L12.4036 4.05414L6.21643 10.2413Z" fill="white" />
  </svg>
);

export const SelectedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="#820AD1" />
    <path d="M7.21643 11.2413C6.98863 11.4691 6.61928 11.4691 6.39147 11.2413L3.09174 7.9416L3.9167 7.11664L6.80395 10.0039L12.5787 4.22919L13.4036 5.05414L7.21643 11.2413Z" fill="white" />
  </svg>
);
