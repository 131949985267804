import React from 'react';
import PropTypes from 'prop-types';

import { CTAButton } from './styles/CTAButton';

const HeaderCTA = ({ btnLabel, href }) => {
  const handleClick = () => {
    window.location = href;
  };

  return (
    <CTAButton
      extended
      variant="contained"
      color="black"
      onClick={handleClick}
      intlKey={btnLabel}
    />
  );
};

HeaderCTA.propTypes = {
  btnLabel: PropTypes.string,
  href: PropTypes.string,
};

HeaderCTA.defaultProps = {
  btnLabel: 'HEADER.MENU.CTA_BUTTON.LABEL',
  href: '/',
};

export default HeaderCTA;
