const ComponentDefaultProps = {
  title: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TITLE',
  },
  subtitle2: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE2',
    strong: true,
    variant: 'paragraph2',
  },
  email: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE',
  },
  confirmEmail: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CONFIRM_EMAIL.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE',
    differentValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.DIFFERENT_VALIDATION_MESSAGE',
  },
  names: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.VALIDATION_MESSAGE',
  },
  firstSurname: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.VALIDATION_MESSAGE',
    maxCharValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.MAX_CHARS.VALIDATION_MESSAGE',
  },
  secondSurname: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.VALIDATION_MESSAGE',
  },
  documentType: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.LABEL',
    validationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.VALIDATION_MESSAGE',
  },
  idNumber: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.LABEL',
    citizenValidationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.CITIZENSHIP_CARD',
    foreignerValidationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.FOREIGNER_ID',
    pptValidationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.PPT',
  },
  phoneNumber: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.VALIDATION_MESSAGE',
  },
  privacyPolicy: {
    label: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY',
    validationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.VALIDATION_MESSAGE',
    links: [{
      name: 'privacyLink2',
      path: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.PATH',
      pathLabel: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.LABEL',
      strong: true,
      color: 'primary',
      variant: 'primary',
    },
    ],
    variant: 'paragraph1',
    strong: false,
  },
  whatsappMessage: {
    label: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE',
    companies: [
      {
        name: 'WhatsApp',
        label: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY',
        strong: true,
        variant: 'link',
        path: 'CUCUTA_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY.URL',
        color: 'primary',
      },
    ],
    variant: 'paragraph1',
    strong: false,
  },
  submitButton: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SUBMIT_BUTTON',
    iconProps: { name: 'arrow-right' },
  },
  snackBar: {
    label: 'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.SNACKBAR_CLOSE',
  },
  genericErrorMessage: {
    label: 'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.GENERIC.ERROR_MESSAGE',
  },
  callout: {
    accordion: {
      title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY.CALLOUT.ACCORDION.TITLE',
      notice: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY_CUCUTA.CALLOUT.INNER_CALLOUT.NOTICE',
      initiallyOpen: false,
      items: [
        {
          itemTitle: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY.CALLOUT.ACCORDION.ITEM_1.TITLE',
          description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY_CUCUTA.CALLOUT.ACCORDION.ITEM_1.DESCIPTION',
        },
        {
          itemTitle: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY.CALLOUT.ACCORDION.ITEM_2.TITLE',
          description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY_CUCUTA.CALLOUT.ACCORDION.ITEM_2.DESCIPTION',
        }],
    },
  },
};

export const culturalHackTreatmentAProps = {
  ...ComponentDefaultProps,
  title: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.TREATMENT_A.TITLE',
  },
  subtitle: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE',
    strong: false,
    variant: 'paragraph1',
  },
  culturalHackPrivacyPolicy: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.PRIVACY_POLICY.LABEL',
    linkLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.PRIVACY_POLICY.HREF.LABEL',
  },
};

export const culturalHackTreatmentBProps = {
  ...ComponentDefaultProps,
  title: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.TREATMENT_B.TITLE',
  },
  subtitle: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE',
    strong: false,
    variant: 'paragraph1',
  },
  culturalHackPrivacyPolicy: {
    label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.PRIVACY_POLICY.LABEL',
    linkLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.PRIVACY_POLICY.HREF.LABEL',
  },
};

export default ComponentDefaultProps;
