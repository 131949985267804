import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const Container = styled.div`
  padding-bottom: 20px;
`;

Container.displayName = 'Container';

export const Title = styled(Typography)`
  margin-bottom: 20px;
`;

Title.displayName = 'Title';

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

ButtonGrid.displayName = 'ButtonGrid';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #F4E7FF;
  cursor: pointer;
  transition: border-color 0.3s;

  ${({ isSelected }) => isSelected
    && css`
      border-color: #820AD1;
      border-width: 2px;
    `}

  &:focus {
    outline: none;
  }
`;

StyledButton.displayName = 'Button';

export const PlaceholderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
`;

PlaceholderIcon.displayName = 'PlaceholderIcon';
