import { css } from 'styled-components';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { useBreakpointsMediaDown } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import { PropTypes } from '@nubank/nuds-web/index-0e40e0c2';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import { SiteContext } from 'components/SiteContext/SiteContext';

import { StyledImg } from '../../styles/Img';
import { StyledImage } from '../../styles/Image';
import { StyledButton } from '../../styles/StyledButton';
import { ImageContainer, QRCodeMultiProduct } from '../../styles/CustomStyles';
import { getReward } from '../../../../../../../domains/culturalHackRewards/rewards';

import { StyledLink } from './styles/Link';

const FeedbackCuentaReward = ({
  defaultProps,
  prospectNames,
  isCulturalHackExperimentBOn,
}) => {
  const { setUsePurpleAsBackgroundInRegistrationForm, loadingTime } = useContext(SiteContext);
  useEffect(() => {
    sendEvent('REALTIME_ANALYSIS_APPROVED', { loadingTime: loadingTime.current });

    return () => setUsePurpleAsBackgroundInRegistrationForm(false);
  }, []);

  const isMobile = useBreakpointsMediaDown('md');

  const fixedReward = getReward('fixed');
  const maxVariableReward = getReward('variable_max');
  const subtitle1Key = isCulturalHackExperimentBOn
    ? defaultProps.subTitleTreatmentB : defaultProps.subTitleTreatmentA;

  return (
    <>
      <Box display="flex" css={ImageContainer}>
        <FormattedMessage id={defaultProps.imageAlt}>
          { msg => (
            <StyledImg
              src={requireImage(defaultProps.image)}
              alt={msg}
            />
          )}
        </FormattedMessage>
      </Box>
      <Typography
        color={defaultProps.titleColor}
        variant="heading3"
        data-testid="FeedbackCuentaRewardTitle"
        intlKey={defaultProps.title}
        gutterBottom={2}
        intlValues={{
          prospectNames,
        }}
      />

      <Typography
        css={css`strong { font-weight: bold; }`}
        color={defaultProps.subTitleColor1}
        variant="subtitle1"
        intlKey={subtitle1Key}
        gutterBottom={2}
        strong
        intlValues={{
          fixedReward,
          maxVariableReward,
        }}
      />

      <Typography
        css={css`strong { font-weight: bold; }`}
        color={defaultProps.subTitleColor2}
        variant="subtitle1"
        intlKey={defaultProps.subTitle2}
        gutterBottom={4}
        strong
        intlValues={{
          br: <br />,
          strong: msg => (
            <strong>{ msg }</strong>
          ),
        }}
      />

      {isMobile ? (
        <StyledLink
          href="https://nuapp.nubank.com.br/bnVhcHA6Ly9zaGVsbC9zcGxhc2hfc2NyZWVuLz91dG1fY2FtcGFpZ249cmVhbC10aW1lLXJlc3BvbnNlLWNvbG9tYmlh"
        >
          <StyledButton
            id="download-app-btn"
            intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.BUTTON.LABEL"
            styleVariant={defaultProps.buttonColor}
            variant="contained"
            extended
          />
        </StyledLink>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          css={QRCodeMultiProduct}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            maxWidth="50%"
          >
            <Icon
              name="arrow-right"
              color={defaultProps.iconColor}
            />
            <Typography
              variant="subtitle1"
              intlKey={defaultProps.footerLabel}
              color={defaultProps.footerLabelColor}
              paddingVertical="16px"
            />
          </Box>
          <StyledImage
            height="8rem"
            width="9rem"
            altIntlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ALT"
            src="registration/openAppQrCode.jpg"
          />
        </Box>
      )}
    </>
  );
};

FeedbackCuentaReward.defaultProps = {
  prospectNames: '',
  isCulturalHackExperimentBOn: false,
};

FeedbackCuentaReward.propTypes = {
  defaultProps: PropTypes.shape({
    buttonColor: PropTypes.string,
    footerLabel: PropTypes.string,
    footerLabelColor: PropTypes.string,
    iconColor: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    subTitle2: PropTypes.string,
    subTitleColor1: PropTypes.string,
    subTitleColor2: PropTypes.string,
    subTitleTreatmentA: PropTypes.string,
    subTitleTreatmentB: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
  }).isRequired,
  isCulturalHackExperimentBOn: PropTypes.bool,
  prospectNames: PropTypes.string,
};

export default FeedbackCuentaReward;
