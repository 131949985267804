import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Container } from './styles/Container';
import { Head, HeadImage } from './styles/Head';

const LoaderIcon = dynamic(() => import('./components/LoaderIcon/LoaderIcon'), { ssr: false });

const StyledHeadImage = styled(HeadImage)`
    max-width: 500px;
`;

function RegistrationRealtimeLoader({ onFormStepChange }) {
  useEffect(() => {
    onFormStepChange(false);
  }, []);

  return (
    <Container>
      <Head>
        <StyledHeadImage>
          <LoaderIcon />
        </StyledHeadImage>
        <Typography
          data-testid="loader-text"
          intlKey="COMMON.WIDGET.REALTIME_LOADER"
          color="primary"
          colorVariant="default"
          intlValues={{
            br: <br />,
            strong: msg => (
              <strong>{msg}</strong>
            ),
          }}
          css={css`strong { font-weight: bold;}`}
        />
      </Head>
    </Container>
  );
}

RegistrationRealtimeLoader.defaultProps = {
  onFormStepChange: () => { },
};

RegistrationRealtimeLoader.propTypes = {
  onFormStepChange: PropTypes.func,
};

export default RegistrationRealtimeLoader;
