import React, { useContext } from 'react';
import Button from '@nubank/nuds-web/components/Button/Button';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { SiteContext } from '../../../../../components/SiteContext/SiteContext';
import { getReward } from '../../../../../domains/culturalHackRewards/rewards';

import { HeaderContainer } from './styles/HeaderContainer';
import {
  HeaderWrapper, ButtonWrapper, TitleWrapper, Styledline,
} from './styles/FixedHeaderContainer';

const FormHeader = ({ displayFixedHeader, formExperiments }) => {
  const {
    closeRegistrationForm,
    usePurpleAsBackgroundInRegistrationForm,
  } = useContext(SiteContext);
  const { formatMessage } = useIntl();

  const fixedReward = getReward('fixed');
  const minVariableReward = getReward('variable_min');
  const maxVariableReward = getReward('variable_max');

  const isCulturalHackExperimentAOn = formExperiments?.isCulturalHackExperimentAOn;
  const isCulturalHackExperimentBOn = formExperiments?.isCulturalHackExperimentBOn;

  const getTitle = () => {
    if (isCulturalHackExperimentAOn) {
      return 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.TREATMENT_A.TITLE';
    }

    if (isCulturalHackExperimentBOn) {
      return 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CULTURAL_HACK.TREATMENT_B.TITLE';
    }

    return 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TITLE';
  };

  const FixedHeader = () => (
    <HeaderWrapper id="fixedHeader">
      <ButtonWrapper>
        <Button
          data-testid="close-btn"
          variant="basic"
          styleVariant="black"
          onClick={closeRegistrationForm}
          iconProps={{
            name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
          }}
        />
      </ButtonWrapper>
      <TitleWrapper>
        <Typography
          gutterBottom={1}
          id="personalInfoStepTitle"
          variant="heading4"
          intlKey={getTitle()}
          intlValues={{
            span: msg => (
              <span>{msg}</span>
            ),
            fixedReward,
            minVariableReward,
            maxVariableReward,
          }}
        />
      </TitleWrapper>
      <Styledline />
    </HeaderWrapper>
  );

  const DefaultHeader = () => (
    <HeaderContainer>
      <Button
        data-testid="close-btn"
        variant="basic"
        styleVariant={usePurpleAsBackgroundInRegistrationForm ? 'white' : 'primary'}
        onClick={closeRegistrationForm}
        iconProps={{
          name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
        }}
      />
    </HeaderContainer>
  );

  return (
    displayFixedHeader ? <FixedHeader /> : <DefaultHeader />
  );
};

FormHeader.defaultProps = {
  displayFixedHeader: false,
  formExperiments: {},
};

FormHeader.propTypes = {
  displayFixedHeader: PropTypes.bool,
  formExperiments: PropTypes.shape({
    isCulturalHackExperimentAOn: PropTypes.bool,
    isCulturalHackExperimentBOn: PropTypes.bool,
  }),
};

export default FormHeader;
