const ComponentDefaultProps = {
  multiproduct: {
    FULL: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          },
          ],
          consumerAdvocate: '',
          consumerAdvocateLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_LINK',
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL_MP',
            nucfLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            nucfPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
            nusaLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUSA',
            nusaPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUSA',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
          advocate: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONSUMER_ADVOCATE.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONSUMER_ADVOCATE_BLOG.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    SAVINGS: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          phones: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CALL_US',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          },
          ],
          consumerAdvocate: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE',
          consumerAdvocateLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_LINK',
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    CREDITS: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          }],
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUSA',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    SAVINGS_WAITLIST: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.REQUESTS.EMAIL.LABEL',
            border: true,
          },
          ],
          consumerAdvocate: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE',
          consumerAdvocateLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_LINK',
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'SAVINGS_WAITLIST.FOOTER.TRANSPARENCY.CONDITIONS.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    FULL_WAITLIST: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          },
          ],
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL_MP',
            nucfLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            nucfPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
            nusaLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUSA',
            nusaPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUSA',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'SAVINGS_WAITLIST.FOOTER.TRANSPARENCY.CONDITIONS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'SAVINGS_WAITLIST.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
  },
  cucuta: {
    FULL: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: true,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.LABEL',
            border: true,
          },
          ],
          consumerAdvocate: '',
          consumerAdvocateLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_LINK',
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            path: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
          advocate: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONSUMER_ADVOCATE.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONSUMER_ADVOCATE_BLOG.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    SAVINGS: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          phones: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CALL_US',
          generals: [{
            text: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.LABEL',
            border: true,
          },
          ],
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            path: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.INSTANT_TRANSFERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.INSTANT_TRANSFERS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    CREDITS: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: true,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.LABEL',
            border: true,
          },
          ],
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            path: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          impact: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.SOCIAL_IMPACT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.SOCIAL_IMPACT.PATH',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },

        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    SAVINGS_WAITLIST: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          phones: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CALL_US',
          generals: [{
            text: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.REQUEST.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.FORECLOSURE.EMAIL.LABEL',
            border: true,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          }, {
            text: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.TEXT',
            mailRef: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.HREF',
            mailLabel: 'CUCUTA_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE.EMAIL.LABEL',
            border: true,
          },
          ],
          consumerAdvocateBlogLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_BLOG_LINK',
          consumerAdvocateOther: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_OTHER',
          consumerAdvocateWeb: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB',
          consumerAdvocateWebLink: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.CONSUMER_ADVOCATE_WEB_LINK',
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            path: 'CUCUTA.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
            variant: 'action',
          },
          ],
        },
        products: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.SAVINGS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CREDIT_CARD_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.PRODUCTS.CDT.PATH',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
    FULL_WAITLIST: {
      navigation: {
        contact: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.TITLE',
          generals: [{
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.HELP.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PRESS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.EVENTS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.PARTNERSHIPS.EMAIL.LABEL',
            border: false,
          }, {
            text: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.TEXT',
            mailRef: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.HREF',
            mailLabel: 'MULTI_PRODUCT_HOME.FOOTER.CONTACT.AUTHORITY.EMAIL.LABEL',
            border: false,
          },
          ],
        },
        transparency: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.TITLE',
          policyPrivacy: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL_MP',
            nucfLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUCF',
            nucfPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUCF',
            nusaLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.LABEL.NUSA',
            nusaPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.PRIVACY_POLICY.PATH.NUSA',
          },
          conditions: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.SAVINGS.LABEL',
            path: 'SAVINGS_WAITLIST.FOOTER.TRANSPARENCY.CONDITIONS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.LABEL',
            path: 'SAVINGS_WAITLIST.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CREDIT_NU_CONTROL.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDITIONS.CDT.PATH',
          }],
          conductCode: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL',
            spanishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.SPANISH',
            spanishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.SPANISH',
            englishLabel: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.LABEL.ENGLISH',
            englishPath: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.CONDUCT_CODE.PATH.ENGLISH',
          },
          ethics: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH',
            text: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.ETHICS.PATH.LABEL',
          },
          financialInformation: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.PATH',
          },
          investor: {
            label: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.TRANSPARENCY.INVESTORS.PATH',
          },
        },
        navigate: {
          title: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.TITLE',
          options: [{
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.ABOUT_US.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.PRESS.PATH',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.CAREERS.PATH',
            variant: 'action',
          }, {
            label: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.LABEL',
            path: 'MULTI_PRODUCT_HOME.FOOTER.NAVIGATE.BLOG.PATH',
            variant: 'action',
          },
          ],
        },
      },
      social: {
        socials: [{
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK',
        }, {
          name: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.NAME',
          link: 'MULTI_PRODUCT_HOME.FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK',
        },
        ],
      },
      company: {
        cucuta: {
          text: 'CUCUTA_HOME.FOOTER.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/logos-compliance.png',
              md: 'multi-product-home/footer/logos-compliance.png',
              lg: 'multi-product-home/footer/logos-compliance.png',
            },
          },
        },
        savings: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.SAVINGS.TEXT',
          regulatorFirst: {
            alt: 'Fogafin',
            images: {
              xs: 'multi-product-home/footer/footer-fogafin-4x.png',
              md: 'multi-product-home/footer/footer-fogafin-4x.png',
              lg: 'multi-product-home/footer/footer-fogafin-4x.png',
            },
          },
          regulatorSecond: {
            alt: 'Superfinanciera',
            images: {
              xs: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              md: 'multi-product-home/footer/footer-superfinanciera-4x.png',
              lg: 'multi-product-home/footer/footer-superfinanciera-4x.png',
            },
          },
        },
        credits: {
          text: 'MULTI_PRODUCT_HOME.FOOTER.CREDITS.TEXT',
          regulatorFirst: {
            alt: 'SIC',
            images: {
              xs: 'multi-product-home/footer/footer-sic-4x.png',
              md: 'multi-product-home/footer/footer-sic-4x.png',
              lg: 'multi-product-home/footer/footer-sic-4x.png',
            },
          },
        },
      },
    },
  },
};

export default ComponentDefaultProps;
