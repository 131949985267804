import {
  LightHouseIcon, LightHumanIcon, LightFlagIcon, LightCloverIcon,
} from '../Icons';

const DefaultProps = {
  title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO.GOALS_COMPONENT.TITLE',
  goals: [
    { id: 1, description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO.GOALS_COMPONENT.FIRST_GOAL.LABEL', icon: LightHouseIcon },
    { id: 2, description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO.GOALS_COMPONENT.SECOND_GOAL.LABEL', icon: LightHumanIcon },
    { id: 3, description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO.GOALS_COMPONENT.THIRD_GOAL.LABEL', icon: LightFlagIcon },
    { id: 4, description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO.GOALS_COMPONENT.FOURTH_GOAL.LABEL', icon: LightCloverIcon },
  ],
};

export default DefaultProps;
