const singleProductClarity = {
  image: 'registration/nuis_light_heart.svg',
  imageAlt: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT',
  title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.SINGLE_PRODUCT.TITLE',
  titleColor: 'default',
  subTitle1: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.SUBTITLE',
  subTitleColor1: 'default',
  footerLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ACCOMPANYING_TEXT',
  footerLabelColor: 'primary',
  iconColor: '#820AD1',
  buttonColor: 'primary',
  steps: [
    { description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.STEPS.STEP_1' },
    { description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.STEPS.STEP_2' },
    { description: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.STEPS.STEP_3' },
  ],
};

const DefaultProps = {
  approved: {
    singleProduct: {
      image: 'registration/cardMoradita.svg',
      imageAlt: 'PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT',
      title: 'PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.TITLE',
      titleColor: 'white.default',
      subTitle1: 'PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.SUBTITLE',
      subTitleColor1: 'white.default',
      footerLabel: 'PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ACCOMPANYING_TEXT',
      footerLabelColor: 'white.default',
      iconColor: 'white',
      buttonColor: 'white',
    },
    multiProduct: {
      image: 'registration/wellDoneHiFive.svg',
      imageAlt: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT',
      title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.TITLE',
      titleColor: 'default',
      subTitle1: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.SUBTITLE',
      subTitleColor1: 'default',
      subTitle2: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.SUBTITLE2',
      subTitleColor2: 'primary',
      footerLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ACCOMPANYING_TEXT',
      footerLabelColor: 'primary',
      iconColor: '#820AD1',
      buttonColor: 'primary',
    },
    singleProductClarity: { ...singleProductClarity },
    multiProductClarity: {
      ...singleProductClarity,
      title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.CLARITY.APPROVAL_SCREEN.MULTIPRODUCT.TITLE',
    },
    culturalHack: {
      image: 'registration/wellDone.svg',
      imageAlt: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT',
      title: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.CULTURAL_HACK.TITLE',
      titleColor: 'default',
      subTitleTreatmentA: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.CULTURAL_HACK.TREATMENT_A.SUBTITLE',
      subTitleTreatmentB: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.CULTURAL_HACK.TREATMENT_B.SUBTITLE',
      subTitleColor1: 'primary',
      subTitle2: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.CULTURAL_HACK.SUBTITLE2',
      subTitleColor2: 'default',
      footerLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ACCOMPANYING_TEXT',
      footerLabelColor: 'primary',
      iconColor: '#820AD1',
      buttonColor: 'primary',
    },
  },
};

export default DefaultProps;
