export function formatAsCurrency(value = 0) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    throw new Error(`"${value}" need to be a number to be formated as currency`);
  }
  return Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatCustomCurrency(value = 0, decimals = 0, hasCurrencySymbol = true, currencyCode = 'COP', currency = 'es-CO') {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    throw new Error(`"${value}" need to be a number to be formated as currency`);
  }

  const options = {
    style: hasCurrencySymbol ? 'currency' : 'decimal',
    currency: hasCurrencySymbol ? currencyCode : undefined,
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  };
  return Intl.NumberFormat(currency, options).format(value);
}
